export default {
  /*
  breakpoints: {
    keys: ['xs', 'xx', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, xx: 360, sm: 600, md: 960, lg: 1280, xl: 1920 },
  },
*/
  palette: {
    type: 'light',
    primary: {
      main: '#b56d3f',
    },
    secondary: {
      main: '#4e0821',
    },
    background: {
      paper: '#eceaea',
      default: '#ffffff',
    },
    divider: '#5d4037',
  },
  nav: {
    logo: '#4e0821', // used for logo bg and header logo text - red
    linkColor: '#ffffff',
    linkHover: '#4e0821', // red
  },
  custom: {
    sectionGap: 4,
    areaGap: 4,
    btnPadding: 3,
    backpanelBG: '#000000', //'#484848', // Dark BG; Sections are layed on top; Named as Primary Light
  },
  rspv: {
    pageBarHeightSmall: '56px',
    pageBarHeightLarge: '64px',

    // pageGap is used for gap in between sections and viewport edge
    pageGapSmall: 0.5, // 1.3, // 1.0 === '8px'
    pageGapMedium: 0.75, // 1.8,
    pageGapLarge: 1.0, // 2.3,

    // section gap is used inside a section
    sectionGapSmall: 1.3, // 1.0 === '8px'
    sectionGapMedium: 1.8,
    sectionGapLarge: 2.3,

    // area gap is used inside an area
    areaGapSmall: 1.0,
    areaGapMedium: 1.5,
    areaGapLarge: 2.0,

    btnPaddingSmall: 1,
    btnPaddingMedium: 1.5,
    btnPaddingLarge: 2,

    btnFontSmall: '100%',
    btnFontMedium: '130%',
    btnFontLarge: '150%',

    btnSpacingSmall: '0.2em',
    btnSpacingMedium: '0.4em',
    btnSpacingLarge: '0.6em',
  },

  // Above is the Gatsby styles
  // Following is the original SPA styles
  chain: {
    backPanelBG: {
      background: '#e8eaf6', // slate area
    },
    itemSelection: {
      background: '#e8eaf6',
    },
    nailSelection: {
      color: '#c1c4e6',
    },
    nailUnSelection: {
      color: '#efefef',
    },
  },
};
