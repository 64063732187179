module.exports = [{
      plugin: require('../../../packages/mui-plugin/gatsby-browser.js'),
      options: {"plugins":[],"webapp":"kripa-site"},
    },{
      plugin: require('../../../packages/layout-plugin/gatsby-browser.js'),
      options: {"plugins":[],"wrapRootElement":true,"navigationPages":[{"name":"About","title":"About Us Page","path":"/about"},{"name":"FAQ","title":"Frequently Asked Questions","path":"/faq"},{"name":"Plans","title":"Pricing Plans","path":"/plans"}]},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
