/* eslint-disable import/prefer-default-export, react/prop-types */

import React from 'react';
import { TopLayout } from "mui-plugin";
import queryString from 'query-string';

require('./static/css/app.css');

export const wrapPageElement = ({ element, props }, themeOptions) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  //console.log('emert-org:gatsby-browser.js:wrapPageElement -------------- invoked');
  return <TopLayout {...props}>{element}</TopLayout>
};

// This is used by PWA.
// When a new build is available, user will see a message in browser to inject the new version of Emert
//
/*
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Application has been updated to bring you more features and stability. Reload and use the latest version?`,
  );
  if (answer === true) {
    window.location.reload();
  }
};
// Custom sw.js is in static folder
export const registerServiceWorker = () => true;
*/

/*
export const onClientEntry = () => {
  //console.log("gatsby-browser: We've started!");
  //console.log('location url:', window.location);
  const parsed = queryString.parseUrl(window.location.href);
  //console.log(parsed);
  if (parsed.query && parsed.query.jwt) {
    localStorage.setItem(process.env.GATSBY_JWT_KEY, parsed.query.jwt);
    window.location = parsed.url;
  }
};
*/
