import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
//
// Use one of :
// 1. Our own internet generated MUI theme
//import themeDefaults from './generated-spa/theme';

// 2. Devia's releae 1.2.0 theme
//import themeDefaults from './theme-devias-120/rawTheme';

// 3. Devia's old theme used by SPA
//import themeDefaults from './theme-old-spa/rawTheme';

// 4. Gatsby's internet generated MUI theme
import themeDefaults from './generated-gatsby/theme';

import customTheme from './custom-theme';
//import enhanceTheme from './enhance-theme';

const genMediaTheme = () => {
  // Merge generated and custom theme
  const currentTheme = {
    ...themeDefaults,
    ...customTheme,
  };
  //console.log('genMediaTheme themeDefaults:', themeDefaults);
  //console.log('genMediaTheme customTheme:', customTheme);
  //console.log('genMediaTheme aggragated currentTheme:', currentTheme);

  // A custom theme for this app
  let theme = createMuiTheme(currentTheme);
  theme = responsiveFontSizes(theme);

  //theme.heroImages = enhanceTheme.heroImages;
  //theme.testimonialPlaceholders = enhanceTheme.testimonialPlaceholders;
  //theme.recallPlaceholders = enhanceTheme.recallPlaceholders;
  //theme.pricePlaceholders = enhanceTheme.pricePlaceholders;
  //console.log('+++++++++++++++++++++++++++ theme from mui');
  return theme;
};

const theme = genMediaTheme();

export { theme };
