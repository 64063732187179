import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme/genMediaTheme';

// Roboto fonts are already provided by the gatrsby-plugin-material-ui
//import './css/mui.css';

export default function TopLayout(props) {
  const t = theme;
  //console.log('mui-plugin:TopLayout.js:TopLayout -------------- invoked' + theme.yatin || 'noyatin');

  return (
    <>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
